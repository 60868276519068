/*
Template Name: Simulor - Responsive Bootstrap 4 Admin Dashboard
Version: 1.0.0
Author: CoderThemes
Email: support@coderthemes.com
File: Main Css File
*/

/* =================
01. General
02. Left Menu 
03. Topbar
04. Page Head
05. Footer
06. Widgets
07. Custom checkbox
08. Custom Radio
09. Ribbons
10. Print
11. Authentication Pages 
12. Components Demos
13. Error Page
14. FAQs
15. Maintenance
16. Timeline
17. Plugins Css
================= */



//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";


// Structure
/* ============ 
01. General
===============*/
@import "custom/structure/general";
/* ============ 
02. Left Menu
===============*/
@import "custom/structure/left-menu";
/* ============ 
03. Topbar
===============*/
@import "custom/structure/topbar";
/* ============ 
04. Page Head
===============*/
@import "custom/structure/page-head";
/* ============ 
05. Footer
===============*/
@import "custom/structure/footer";


//Components
/* ============ 
06. Widgets
===============*/
@import "custom/components/widgets";
/* ============ 
07. Custom checkbox
===============*/
@import "custom/components/custom-checkbox";
/* ============ 
08. Custom Radio
===============*/
@import "custom/components/custom-radio";
/* ============ 
09. Ribbons
===============*/
@import "custom/components/ribbons";
/* ============ 
10. Print
===============*/
@import "custom/components/print";


//Pages
/* ============ 
11. Authentication Pages 
===============*/
@import "custom/pages/authentication";
/* ============ 
12. Components Demos
===============*/
@import "custom/pages/components-demo";
/* ============ 
13. Error Page
===============*/
@import "custom/pages/error";
/* ============ 
14. FAQs
===============*/
@import "custom/pages/faq";
/* ============ 
15. Maintenance
===============*/
@import "custom/pages/maintenance";
/* ============ 
16. Timeline
===============*/
@import "custom/pages/timeline";
/* ============ 
17. navbar
===============*/
@import "custom/structure/navbar";


// Vendors
/* ============ 
18. Plugins Css
===============*/
@import "vendor/metisMenu";
@import "vendor/daterangepicker";
@import "vendor/jquery.toast.min";
@import "vendor/select2.min";
@import "vendor/nice-select";


// Plugins
@import "custom/plugins/ion-rangeslider";
@import "custom/plugins/calendar";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/datatable";
@import "custom/plugins/daterange";
@import "custom/plugins/form-wizard";
@import "custom/plugins/maps";
@import "custom/plugins/select2";
@import "custom/plugins/toaster";
@import "custom/plugins/sweetalert";
@import "custom/plugins/custom-select";
@import "page";


@import "../assets/fonts/iconfont.css";
.iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

.topbar-custom {
    width: 100%;
    height: 60px;
    background: rgba($color: #000000, $alpha: 0.3)!important;
    position: fixed;
    top:0px;
    left:0px;
    z-index: 1111;
    .navbar-content{
        width: 1200px;
        margin: 0 auto;
        .logo{
            width: 230px;
            height: 100%;
            margin-left: 8px;
            line-height: 60px;
            float: left;
            img{
                width: 122px;
                vertical-align: middle;
            }
        }
        .navMenu{
            float: left;
            margin-left: 18%;
            >ul{
                height: 57.5px;
                line-height: 57.5px;
                >li{
                    list-style: none;
                    float: left;
                    box-sizing: border-box;
                    a{
                       width: 100%;
                       height: 100%;
                       padding: 0px 35px;
                       color: #ffffff;
                       display: inline-block;
                    }
                }
            }
        }
        .navRight{
            float: right;
            height: 60px;
            line-height: 60px;
            min-width: 120px;
            position: relative;
            .login{
                width: 74px;
                height: 32px;
                line-height: 32px;
                background: #ff9c38;
                margin-top: 14px;
                cursor: pointer;
                text-align: center;
                float: left;
                a{
                  color: #ffffff;
                  width: 100%;
                  height: 100%;
                  line-height: 32px;
                  display: inline-block;
                }
            }
            .resgister{
                width: 74px;
                height: 32px;
                float: left;
                color: #ffffff;
                margin-top: 14px;
                cursor: pointer;
                margin:0px 20px;
                a{
                    color: #ffffff;
                    width: 100%;
                    height: 100%;
                  }
            }
            span{
                color: #ffffff;
                vertical-align: middle;
            }
            >a{
                height: 60px;
                min-width: 120px;
                display: inline-block;
                >span:nth-child(1){
                    margin-right: 15px;
                } 
                >span:nth-child(2){
                    width: 25px;
                    height: 20px;
                    margin-right: 10px;
                    >img{
                        width: 25px;
                        height: 20px;
                        border-radius: 50%;
                    }
                }
                >span:nth-child(3){
                    width: 40px;
                    height: 40px;
                    >img{
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}
.nav-active{
    border-bottom: 2px solid #fff;
    font-weight: bold;
}
.menutips{
    width: 120px;
    margin-top: -1px;
    display:none;
    position: absolute;
    top: 61px;
    right: 82px;
    >li{
        padding: 0px;
        margin:0px;
        height: 40px;
        width: 160px;
        background: #30376b;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
        >a{
           height: 40px;
           width: 160px;
           color: #ffffff;
           display: inline-block;
        }
        >a:hover{
            height: 40px;
            width: 160px;
            background: #434C8D;
        }
    }
}
// 
// footer.scss
//
.footer {
    height: 290px;
    width: 100%;
    padding: 38px 15px  10px;
    color: #ffffff;
    background-color:#232526;
    box-sizing: border-box;
    .mediaFooter{
        min-width: 1200px;
        height: 100%;
        width: 1200px;
        margin:0 auto;
        .companyInfo{
            height: 68%;
            // border-bottom:1px solid #c6c6c6;
            //text-align: center;
            position: relative;
            .companyInfoBox{
                width: 160px;
                height: 100%;
                float: left;
                margin-right:40px; 
                >p:nth-child(1){
                    color: #ffffff;
                    font-size: 14px;
                    margin-bottom: 20px;
                }
                >p:nth-child(2){
                    color: #c6c6c6;
                    font-size: 16px;
                }
                >ul{
                    >li{
                        width: 100%;
                        height: 25px;
                        font-size: 12px;
                        color: #c6c6c6;
                        a{
                            font-size: 12px;
                            color: #c6c6c6;
                        }
                    }
                }
    
            }
            .companyInfoBox:nth-child(1){
                margin-left: 160px;
            }
            .companyInfoBox:nth-child(4){
               width: 220px;
            }
            .companyInfoBox2{
                width: 160px;
                height: 100%;
                float: right;
                img{
                    width: 100px;
                    height: 100px;
                }
                p{
                    margin-bottom: 10px;
                    font-size: 14px;
                }
            }
            hr{
                width: 1400px;
                background: #c6c6c6;
                position: absolute;
                bottom: -5px;
                left: 0;
                margin-left: -100px;
            }
        }
        .companydesc{
            margin-top:1rem;
            width: 1200px;
            text-align: center;
            >p{
              color: #c6c6c6;
               font-size: 12px;
               >span:nth-child(1){
                margin-right: 10px;
               }
            }
            >p:nth-child(1){
                margin-bottom: 1rem;
            }
        }
        
    }
}
.navfooter{
    width: 100%;
    height: 40px;
    background: #232526;
    position: fixed;
    bottom:0;
    z-index: 111;
    .systemFooter{
        width: 1200px;
        height: 40px;
        line-height: 40px;
        margin:0 auto;
        text-align: center;
        span{
            color: #ffffff;
        }
        >span:nth-child(2){
            margin-left: 30px;
        }
        >span:nth-child(3){
            margin-left: 30px;
        }
        >span:nth-child(4){
            margin-left: 30px;
        }
    }
}
.footerbg{
    width: 100%;
    height: 40px;
    background: #ffffff;
    position: absolute;
    bottom:0;
    z-index: 111;
    .systemFooter{
        width: 1200px;
        height: 40px;
        line-height: 40px;
        margin:0 auto;
        text-align: center;
        span{
            color: #333333;
        }
        >span:nth-child(2){
            margin-left: 30px;
        }
        >span:nth-child(3){
            margin-left: 30px;
        }
        >span:nth-child(4){
            margin-left: 30px;
        }
    }
}
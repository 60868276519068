.navbar-custom {
    width: 100%;
    height: 60px;
    background: rgba($color: #000000, $alpha: 0.3)!important;
    position: fixed;
    top:0px;
    left:0px;
    z-index: 1111;
    .navbar-content{
        width: 1200px;
        margin: 0 auto;
        .logo{
            width: 230px;
            height: 100%;
            margin-left: 8px;
            line-height: 60px;
            float: left;
            img{
                width: 122px;
                vertical-align: middle;
            }
        }
        .navRights{
            float: right;
            .backIndex{
                height: 32px;
                line-height: 32px;
                color: #ffffff;
                margin-top: 14px;
                cursor: pointer;
                display: inline-block;
                a{
                    color: #ffffff;
                    width: 100%;
                    height: 100%;
                  }
            }
        }
    }
}
.P_page div, .P_page, .P_page span, .P_page input {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.P_page div, .P_page span, .P_page input{
    background: #FFFFFF;
}
.P_page {
    width: 100%;
    height: 40px;
    min-width: 900px;
    margin-bottom: 30px;
    margin-top: 30px;
}

.P_pageText {
    width: auto;
    height: 40px;
    float: right;
    display: inline-block;
    color: #4b56a5;
    line-height: 40px;
    margin-right: 17px !important;
    background: rgba(255,255,255,0)!important;
}
.P_pageText span{
    background: rgba(255,255,255,0)!important;
}
.P_toPage {
    float: right;
    width: auto;
    height: 40px;
    display: inline-block;
    background: rgba(255,255,255,0)!important;
}

.P_toPage input {
    outline: none;
    display: inline-block;
    width: 50px;
    min-width: 50px;
    height: 40px;
    border-radius: 3px;
    border: 1px solid #ECECEC;
    text-align: center;
}

.P_toPage div {
    width: 50px;
    height: 40px;
    border-radius: 3px;
    border: 1px solid #C8C8C8;
    color: #333333;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-left: 10px !important;
    margin-right: 20px;
}

.P_next, .P_previous, .P_last {
    width: auto;
    height: 40px;
    min-width: 50px;
    border-radius: 3px;
    border: 1px solid #ECECEC;
    text-align: center;
    line-height: 40px;
    display: inline-block;
    padding: 0 15px !important;
    font-size: 14px;
    float: right;
    margin-right: 10px !important;
    cursor: pointer;
}

.P_next:hover, .P_previous:hover, .P_last:hover, .P_toPage div:hover, .P_pageNum div:hover {
    background: rgba(75,85,165,1) !important;
    border: 1px solid rgba(75,85,165,1) !important;
    color: #ffffff !important;
}

.P_last {
    margin-right: 50px !important;
}

.P_pageNum {
    width: auto;
    height: 40px;
    display: inline-block;
    float: right;
    background: rgba(255,255,255,0)!important;
}

.P_pageNum div {
    width: auto;
    height: 40px;
    line-height: 40px;
    border-radius: 3px;
    border: 1px solid #ECECEC;
    text-align: center;
    padding: 0 15px !important;
    font-size: 14px;
    display: inline-block;
    margin-right: 10px !important;
    cursor: pointer;
}

.P_active {
    background: #4b56a5 !important;
    border: 1px solid rgba(75,85,165, 1) !important;
    color: #ffffff !important;
}

.P_next, .P_previous, .P_last, .P_toPage div,.P_pageNum div{
    position: relative;
    overflow: hidden;
}

.P_next:after, .P_previous:after, .P_last:after, .P_toPage div:after,.P_pageNum div:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #666 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform .3s, opacity .5s;
}

.P_next:active:after, .P_previous:active:after, .P_last:active:after, .P_toPage div:active:after,.P_pageNum div:active:after {
    transform: scale(0, 0);
    opacity: .3;
    transition: 0s;
}

.C_notData{
    width: calc(100% - 60px);
    height: 86px;
    margin: 50px 30px;
    display: none;
}
.C_notData img{
    display: block;
    width: 122px;
    height: 100%;
    margin: 0 auto;
}


.C_table tr td:first-child,.C_table tr th:first-child,
.C_table1 tr td:first-child,.C_table1 tr th:first-child,
.C_table2 tr td:first-child,.C_table2 tr th:first-child{
    padding-left: 30px !important;
}


.C_table2,.C_table1{
    display: table;
    width: 100%;
    height: auto;
    border-spacing: 0;
    border-collapse: collapse;
}


.C_table2>thead{
    border-bottom: 1px solid #dddddd;
}
td,th{
    display: table-cell;
}
.C_table1>tbody>tr:hover,
.C_table2>tbody>tr:hover{
    background-color: #f5f5f5;
}
.C_table1>tbody>tr:nth-child(2n){
    background: #f9f9f9;
}
.C_table2>tbody>tr:nth-child(2n){
    background: #f9f9f9;
}
.metismenu {
    padding: 0;
    ul {
        padding: 0;
        >li {
            display: block;
            list-style: none;
            width: 100%;
        }
    }
}
#wrapper {
    height: 100%;
    overflow: hidden;
    width: 100%;
    min-width: 1200px!important;
    clear: both;
}
//Content Page
.content-page {
    margin-left: $leftbar-width;
    overflow: hidden;
    padding: 5px 20px;
    min-height: 80vh;
}

// Sidemenu
.left-side-menu {
    //width: 240px!important;
    width: $leftbar-width;
    z-index: 10;
    background: $bg-leftbar;
    border-right: 2px solid darken($bg-leftbar,2%);
    bottom: 0;
    padding: 80px 0;
    position: fixed;
    transition: all .2s ease-out;
    top: 0;
}
.content{
    padding-top: 65px!important;

}
// Sidebar
#sidebar-menu {
    >ul {
        >li {
            display: list-item;
            margin-bottom: 26px;
            width: 100%;
            height: 40px;
            line-height: 36px;
            >a {
                color: $menu-item;
                display: block;
                width: 100%;
                height: 40px;
                font-size: 0.9375rem;
                position: relative;
                transition: all 0.4s;
                &:hover,
                &:focus,
                &:active {
                    color: $menu-item-hover;
                    text-decoration: none;
                }
                >span {
                    vertical-align: middle;
                    display: inline-block;
                    line-height: 20px;
                }
                >span:nth-child(1){
                    margin-right: 10px;
                    margin-left: 30px;
                }
                i {
                    display: inline-block;
                    line-height: 1.0625rem;
                    margin: 0;
                    text-align: center;
                    vertical-align: middle;
                    width: 20px;
                }
            }
        }
        >li.active{
            background: #4B56A5;
            >a{
                color: $menu-item-active!important;
            }
        }

    }
}
// 
// maps.scss
//

//Google Maps

.gmaps,
.gmaps-panaroma {
  height: 300px;
  background: $light;
  border-radius: 3px;
}


// Vector Map 

.jvectormap-label {
  border: none;
  background: $dark;
  color: $white;
  font-family: $font-family-base;
  font-size: $font-size-base;
  padding: 5px 8px;
}
.C_marker{
  position: absolute;
  width: 24px;
  height: 31px;
  border-radius: 5px;
  background: url("../assets/images/mapIcon.png");
  background-size: 24px 31px;
}
.C_marker.huang {
  width: 28px;
  height: 37px;
  background-image: url("../assets/images/mapIcon1.png");
  background-size: 28px 37px;
}
.C_marker.hong {
  width: 28px;
  height: 37px;
  background-image: url("../assets/images/mapIcon2.png");
  background-size: 28px 37px;
}

//.C_marker img{
//  position: absolute;
//  top: 152px;
//  left: 0;
//  width: 28px;
//  height: 33px;
//  display: block;
//  z-index: 99;
//}
.C_markerInfo{
  position: relative;
  width: 294px;
  // height: 152px;
}
.C_mapMsgBox{
  position: absolute;
  top: 0;
  /*top: -140px;*/
  left: 0;
  /*left: -128px;*/
  width: 294px;
  height: 152px;
  /*display: none;*/
  background: url("../assets/images/bg.png");
  cursor: pointer!important;
  z-index: 99999999999;
}
.C_mapMsgContent{
  position: absolute;
  top: 9px;
  left: 12px;
  width: 272px;
  height: 130px;
  padding-left: 10px;
  font-family: "Microsoft YaHei";
}
.C_mapMsgTitle{
  width: 100%;
  height: 40px;
}
.C_mapMsgTitle span:first-child{
  display: inline-block;
  margin-top: 11px;
  line-height: 18px;
  font-size: 18px;
  color: #333333;
}
//.C_mapEleCounts{
//  display: inline-block;
//  width: auto;
//  height:20px;
//  margin-top: 17px;
//  margin-left: 30px;
//  line-height: 12px;
//  font-size: 12px;
//  color: #e41e1e;
//}
.C_mapAddress{
  width: 100%;
  height: auto;
  margin-top: 20px;
  line-height: 20px;
  font-size: 14px;
  color: #999999;
  padding-left: 22px;
  padding-right: 15px;
  //background: url("../assets/images/icon1.png") no-repeat left top 4px;
}
.C_mapMsgDetails{
  width: 100%;
  height: auto;
  line-height: 20px;
  margin-top: 5px;
  font-size: 14px;
  color: #438ff0;
  padding-left: 22px;
}
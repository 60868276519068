// 
// page-title.scss
//

.page-title-box {
    .page-title {
        font-size: 18px;
        margin: 0;
        line-height: 75px;
        color: $gray-900;
    }
    .page-title-right {
        float: right;
        margin-top: 20px;
    }

    .breadcrumb {
        padding: 0;
    }
}


@include media-breakpoint-down(sm) {
    .page-title-box {
        .page-title {
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 70px;
        }
        .breadcrumb {
            display: none;
        }
    }
}

@media (max-width: 640px) {
    .page-title-box {
        .page-title-right {
            display: none;
        }
    }
}

@media (max-width: 419px) {
    .page-title-box .breadcrumb {
        display: none;
    }
}
.breadcrumb a{
    color: #656565;
}
.breadcrumb-item.active{
    color: #0c91cc;
}
.page-title-box{
    width: calc(100% + 70px);
    margin-left: -35px;
    margin-right: -35px;
    background: #FFFFFF;
    padding-left: 35px;
    height: 57px;
    line-height: 57px;
}